/* eslint-disable react/jsx-one-expression-per-line */
import { GetServerSidePropsContext, NextPage } from 'next';
import dynamic from 'next/dynamic';
import React, { useCallback, useMemo } from 'react';

import TransparentButton from '@/atoms/button-transparent';
import DynamicLoader from '@/atoms/dynamic-loader';
import { HomeAmenityData } from '@/molecules/home-amenity';
import { propsErrorWrapper } from '@/utils/callQueries.helpers';
import { getHomeAmenities } from '@core/amenities/actions/getHomeAmenities';
import { useSettingsStore } from '@core/settings/store/settings';
import HomeFooter from './components/Footer';
import { getHomeSections } from '@core/home/actions/getHomeSections';
import { ListingSectionsDto } from '@graphql/hooks-and-types';

export enum HomeSectionsSlugs {
  TEAM = 'team_members_section',
  TESTIMONIALS = 'testimonials_section',
  AMENITIES = 'AMENITIES',
}

const HomeCards = dynamic(() => import('./components/HomeCards'), {
  ssr: false,
  loading: () => (
    <div className="flex h-[600px] justify-center items-center">
      <DynamicLoader />
    </div>
  ),
});

const AboutUs = dynamic(() => import('./components/AboutUs'), {
  ssr: false,
  loading: () => (
    <div className="flex h-[500px] justify-center items-center">
      <DynamicLoader />
    </div>
  ),
});

const Teams = dynamic(() => import('./components/Team'), {
  ssr: false,
  loading: () => (
    <div className="flex bg-error h-[200px] justify-center items-center">
      <DynamicLoader />
    </div>
  ),
});

const Testimonials = dynamic(() => import('./components/Testimonials'), {
  ssr: false,
  loading: () => (
    <div className="flex h-[200px] justify-center items-center">
      <DynamicLoader />
    </div>
  ),
});

const Amenities = dynamic(() => import('./components/Amenities'), {
  loading: () => (
    <div className="flex h-[200px] justify-center items-center">
      <DynamicLoader />
    </div>
  ),
});

const HomeViewPage: NextPage<{
  error?: string;
  clientName: string;
  amenities: HomeAmenityData[];
  homeSections: ListingSectionsDto[];
}> = ({ amenities, homeSections }) => {
  const { getSettings } = useSettingsStore();
  // TODO handle versions from backend
  const isOldVersion = !getSettings();
  const teamSectionData = useMemo(
    () => homeSections?.find((section) => section.slug === HomeSectionsSlugs.TEAM),
    [homeSections],
  );
  const testimonialsSectionData = useMemo(
    () => homeSections?.find((section) => section.slug === HomeSectionsSlugs.TESTIMONIALS),
    [homeSections],
  );
  const gotoPickspaceContact = useCallback(() => {
    window.location.href = 'https://pickspace.com/contact';
  }, []);

  return isOldVersion ? (
    // handle old version better later if we will disable the whole system or just pop up a message
    <div className="flex flex-col w-full items-center justify-center h-screen">
      <p className="text-center text-error">
        You are running on an older version please contact us to get the new version
      </p>
      <div>
        <TransparentButton
          onClick={gotoPickspaceContact}
          className="bg-secondary text-black px-5 py-2 h-10 w-fit rounded-lg mb-10 font-bold cursor-pointer"
        >
          Contact Us
        </TransparentButton>
      </div>
    </div>
  ) : (
    <div className="overflow-auto w-full absolute bg-white">
      <AboutUs />
      <HomeCards />
      <Amenities amenities={amenities} />
      <Teams data={teamSectionData} />
      <Testimonials data={testimonialsSectionData} />
      <HomeFooter />
    </div>
  );
};

export async function getServerSideProps(context: GetServerSidePropsContext) {
  if (!context.query.clientName) {
    return propsErrorWrapper({ message: 'invalidClient' });
  }
  try {
    const amenities = (await getHomeAmenities(context.query.clientName as string)) || [];
    const homeSections = (await getHomeSections(context.query.clientName as string)) || [];
    return { props: { amenities, homeSections } };
  } catch (error) {
    return propsErrorWrapper(error, { amenities: [] });
  }
}

export default HomeViewPage;
